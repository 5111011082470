import { getSysDictionaryByKey, getSysParamsByKey } from '@/api/sysParams'

// 系统参数与字典
const state = {
  grade: [], // 年级
  stuStatus: [], // 学籍状态
  teacherType: [],
  majorType: [],
  lengthOfSchoolingType: [], // 学制
  weekList: '日一二三四五六日',
  // 自定义导出学生的字段 list 选择
  exportStuKeys: {
    stuName: '姓名',
    stuNo: '学号',
    sexType: '性别',
    birthday: '生日',
    nation: '民族',
    political: '政治面貌',
    admissionStage: '录取阶段',
    admissionBatch: '录取批次名称',
    categoryName: '计划类别名称',
    admissionCategory: '录取类别',
    admissionMark: '录取总分',
    examineNo: '录取编号',
    examineCode: '考生号',
    examineCodeNo: '考生代码',
    origin: '生源地',
    subjectKind: '科类名称',
    middleSchool: '中学名称',
    fixedPhone: '电话',
    linkMan: '联系人',
    linkManPhone: '联系人电话',
    fatherName: '父亲姓名',
    fatherPhone: '父亲电话',
    motherName: '母亲姓名',
    motherPhone: '母亲电话',
    postCode: '邮政编码',
    addressee: '收件人',
    province: '省',
    city: '市',
    region: '区',
    addressDetails: '详细地址',
    stuCategory: '学生类别',
    graduateCategory: '毕业类别',
    stuStatus: '学生状态',
    signIn: '学生是否已报到',
    clothesFlag: '是否已经领了校服',
    campusCardFlag: '是否已经领了校园卡',
    height: '身高',
    weight: '体重',
    shoeSize: '鞋码',
    coatSize: '上衣尺码',
    pantSize: '裤子尺码',
    bachelorIntentionSchool: '专升本意向学校',
    bachelorIntentionMajor: '专升本意向专业',
    idNumber: '身份证号',
    majorName: '专业名字',
    roomName: '宿舍号',
    className: '班级名',
    grade: '年级',
    collegeName: '院系名称',
    dormitoryBedNo: '宿舍床号',
    floorNum: '层数',
    dormitoryName: '宿舍栋名',
    broadbandAccount: '宽带账号',
    remarks: '备注'
  },
  sexType: [{
    type: 1,
    name: '男'
  }, {
    type: 2,
    name: '女'
  }],
  yearItem: [{
    type: 1,
    name: '第一学年'
  }, {
    type: 2,
    name: '第二学年'
  }, {
    type: 3,
    name: '第三学年'
  }],
  payType: [{
    type: 1,
    name: '扣费'
  }, {
    type: 2,
    name: '现金'
  }, {
    type: 3,
    name: '刷卡'
  }, {
    type: 4,
    name: '汇款'
  }, {
    type: 5,
    name: '助贷'
  }, {
    type: 6,
    name: '减免'
  }, {
    type: 7,
    name: '退还'
  }, {
    type: 8,
    name: '移动支付'
  }, {
    type: 9,
    name: '移动支付:中行'
  }, {
    type: 10,
    name: '移动支付:工行'
  }, {
    type: 11,
    name: '其他'
  }],
  reviewStatus: [{
    paramValue: 1,
    paramName: '未审核',
    label: '审核中',
    btnType: 'primary'
  }, {
    paramValue: 2,
    paramName: '不通过',
    label: '不通过',
    btnType: 'danger'
  }, {
    paramValue: 3,
    paramName: '已审核',
    label: '已通过',
    btnType: 'success'
  }],
  greenRoadStatus: [{
    paramValue: 1,
    paramName: '贷款'
  }, {
    paramValue: 2,
    paramName: '分期'
  }, {
    paramValue: 3,
    paramName: '最低缴费'
  }, {
    paramValue: 4,
    paramName: '三次缴费'
  }],
  params: {} // 参数
}

const mutations = {
  // 设置年级
  setGradeList(state, data) {
    state.grade = data
  },
  // 设置学籍状态
  setStuStatus(state, data) {
    state.stuStatus = data
  },
  // 设置教师职务
  setTeacherType(state, data) {
    state.teacherType = data
  },
  setParams(state, data) {
    state.params = data
  },
  setMajorType(state, data) {
    state.majorType = data
  },
  setLengthOfSchoolingType(state, data) {
    state.lengthOfSchoolingType = data
  }
}

const actions = {
  // 获取系统字典
  getSystemDictionary({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSysDictionaryByKey(params).then(res => {
        switch (params) {
          case 'grade':
            commit('setGradeList', res.data)
            break
          case 'stuStatus':
            commit('setStuStatus', res.data)
            break
          case 'teacherType':
            commit('setTeacherType', res.data)
            break
          case 'majorType':
            commit('setMajorType', res.data)
            break
          case 'lengthOfSchoolingType':
            commit('setLengthOfSchoolingType', res.data)
            break
        }
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取系统字典
  getSystemParams({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSysParamsByKey(params).then(res => {
        commit('setParams', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
